import React, { useEffect, useState } from 'react'
import Chart from 'react-google-charts'



const MultiLineChart = () => {

    const [TCdata,setTCdata] = useState([]);
    const [PDdata,setPDdata] = useState([]);
    const [ALdata,setALdata] = useState([]);

    // Attack Layer Graph
    const ALLineChartOptions = {
      hAxis: {
        title: 'Time Axis',
        slantedText:true,
        slantedTextAngle:50,
        showTextEvery:true,
        textPosition:'none'
      },
      vAxis: {
        title: 'Attack Layer 3',
      },
      series: {
        1: { curveType: 'function' },
      },
      chartArea:{width:'70%'},
      legend:'bottom',
      title: 'DDOS Attack Graph'
    }
    useEffect(() => {
      const getData = async () => {
      const url = "https://task1.maazintern.workers.dev/attack-layer3"
      //const url = 'https://www.7timer.info/bin/api.pl?lon=113.17&lat=23.09&product=astro&output=json';
      try {
          const response = await fetch(url);
          const data = await response.json();

          console.log(data)
          let arr = []
          arr.push(["x","total"])
          for(var i=0; i < 720; i++){

            let numb = parseInt(i,10)
            let k1 = "total/values/"+numb
            arr.push([i,parseFloat(data?.data[k1])])
        
          }

          setALdata(arr)
          console.log(arr)
      }
      catch (error) {
          console.log(error);
      }
      };
      getData();
    }, []);

    // Traffic Change Graph
    const TCLineChartOptions = {
      hAxis: {
        title: 'Time Axis',
        slantedText:true,
        slantedTextAngle:50,
        showTextEvery:true,
        textPosition:'none'
      },
      vAxis: {
        title: 'Traffic Change',
      },
      series: {
        1: { curveType: 'function' },
      },
      chartArea:{width:'70%'},
      legend:'bottom',
      title: 'Traffic Change Graph'
    }

    useEffect(() => {
      const getData = async () => {
      const url = "https://task1.maazintern.workers.dev/traffic-change"
      //const url = 'https://www.7timer.info/bin/api.pl?lon=113.17&lat=23.09&product=astro&output=json';
      try {
          const response = await fetch(url);
          const data = await response.json();

          console.log(data)
          let arr = []
          arr.push(["x","total","http"])
          for(var i=0; i < 721; i++){

            let numb = parseInt(i,10)
            let k1 = "total/values/"+numb
            let k2 = "http/values/"+numb
            arr.push([i,parseFloat(data?.data?.total[k1]),parseFloat(data?.data?.http[k2])])
        
          }

          setTCdata(arr)
        }
      catch (error) {
          console.log(error);
      }
      };
      getData();
    }, []);

    // Popular Domains Graph
    const PDLineChartOptions = {
      hAxis: {
        title: 'Domain Name',
        slantedText:true,
        slantedTextAngle:50,
        showTextEvery:true
      },
      vAxis: {
        title: 'Popularity',
      },
      series: {
        1: { curveType: 'function' },
      },
      chartArea:{width:'70%'},
      legend:'bottom',
      title: 'Popular Domain Graph'
    }

    useEffect(() => {
        const getData = async () => {
        const url = "https://task1.maazintern.workers.dev/popular-domains"
        //const url = 'https://www.7timer.info/bin/api.pl?lon=113.17&lat=23.09&product=astro&output=json';
        try {
            const response = await fetch(url);
            const data = await response.json();

            console.log(data)

            let arr = []
            arr.push(['Domain Name','Current Rank','Previous Rank'])
            Object.keys(data.rankingEntries).forEach(function(key) {

                arr.push([
                  data?.rankingEntries[key].domain,
                  parseInt(data?.rankingEntries[key].rank,10),
                  parseInt(data?.rankingEntries[key].rank,10) + parseInt(data?.rankingEntries[key].rankChange,10)
                ])

            });
            
            setPDdata(arr)

            console.log(arr)
        }
        catch (error) {
            console.log(error);
        }
        };
        getData();
    }, []);

    

    return (
        
        [<Chart
            width={'800px'}
            height={'410px'}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={ALdata}
            options={ALLineChartOptions}
            rootProps={{ 'data-testid': '1' }}
        />,
        <Chart
            width={'800px'}
            height={'500px'}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={TCdata}
            options={TCLineChartOptions}
            rootProps={{ 'data-testid': '2' }}

        />
        ,
        <Chart
            width={'800px'}
            height={'410px'}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={PDdata}
            options={PDLineChartOptions}
            rootProps={{ 'data-testid': '3' }}
        />
      ]
        
    )
}
export default MultiLineChart