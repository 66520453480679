import './App.css';
import MultiLineChart from './Charts/MultiLineChart';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

function App() {
  
  return (
    <div className="App">
      <div>  
      <MultiLineChart  />
      </div>
    </div>
    
  );
}

export default App;
